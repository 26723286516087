<template>
  <div id="SportAccessControl">
    <card style="grid-column: 1 / span 12">
      <div class="kpi-grid">
        <qs-kpi
          qlik-id="TRDYKK"
          style="font-size: 15px"
          class="sport-scans-color"
        />
        <qs-kpi
          qlik-id="jZufG"
          style="font-size: 15px"
          class="sport-scanned-ticket-color"
        />
        <qs-kpi
          qlik-id="nDMr"
          style="font-size: 15px"
          class="sport-unscanned-ticket-color"
        />
        <qs-kpi qlik-id="RPPRva" style="font-size: 15px" />
        <qs-kpi qlik-id="sSjT" style="font-size: 15px" />
        <qs-kpi qlik-id="jfmcFL" style="font-size: 15px" />
        <qs-kpi qlik-id="qPYFH" style="font-size: 15px" />
      </div>
    </card>

    <QsCard
      :qlik-ids="['pPzEVJH']"
      style="grid-column: 1 / span 12"
      card-style="compact"
    />

    <QsCard :qlik-ids="['DjmSA']" style="grid-column: 1 / span 7" />

    <div
      style="
        grid-column: 8 / span 5;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      "
    >
      <card style="height: 30%">
        <div
          class="grid-with-buttons"
          style="
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            height: 45%;
          "
        >
          <QsCard
            :qlik-ids="['QNmmf']"
            style="grid-column: 1 / span 3; grid-row: 1 / span 1"
          />
        </div>
        <div class="flex-container" style="display: flex; height: 55%">
          <QsCard :qlik-ids="['XDpHmp']" style="flex-grow: 1" />
          <QsCard :qlik-ids="['UpPdJP']" style="flex-grow: 1" />
          <QsCard :qlik-ids="['quAqjPr']" style="flex-grow: 1" />
        </div>
      </card>

      <card style="flex-grow: 1">
        <QsCard :qlik-ids="['CMaPwjr']" style="height: 100%" />
      </card>
    </div>

    <QsTabs :qlik-ids="['BcBzPFJ', 'Cfg']" style="grid-column: 1 / span 12" />

    <QsCard :qlik-ids="['YncvPf']" style="grid-column: 1 / span 8" />
    <QsCard :qlik-ids="['jzjPgv']" style="grid-column: 9 / span 4" />

    <QsTabs
      :qlik-ids="['SVtFUxa', 'PjrtNEF']"
      style="grid-column: 1 / span 6"
    />
    <QsCard :qlik-ids="['dJCabh']" style="grid-column: 7 / span 6" />

    <QsTabs :qlik-ids="['MPHHSj', 'qxwkR']" style="grid-column: 1 / span 12" />
  </div>
</template>

<script>
import Card from "@/components/Card";
import QsKpi from "@/components/Qlik/QsKpi";
import QsCard from "@/components/Qlik/QsCard";
import QsTabs from "@/components/Qlik/QsTabs";

export default {
  components: { Card, QsCard, QsTabs, QsKpi }
};
</script>

<style scoped>
#SportAccessControl {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 100px 45px 400px 480px 480px 380px 480px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}

.kpi-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 100%;
  align-items: center;
}

.grid-with-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 70px 50px 250px;
}
</style>

<style>
.sport-scans-color span {
  color: RGB(68, 119, 170);
}

.sport-scanned-ticket-color span {
  color: RGB(93, 228, 193);
}

.sport-unscanned-ticket-color span {
  color: RGB(120, 33, 97);
}
</style>
